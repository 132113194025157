import moment from "moment";

export const isDateTimeValid = (date1, operator, date2, granularity) => {
  try {
    if (!date1 || !date2) {
      return false;
    }
    const d1 = moment(date1);
    const d2 = moment(date2);
    if (operator === ">") {
      return d1.isAfter(d2);
    } else if (operator === ">=") {
      return d1.isSameOrAfter(d2);
    } else if (operator === "<") {
      return d1.isBefore(d2);
    } else if (operator === "<=") {
      return d1.isSameOrBefore(d2);
    } else if (operator === "=") {
      return d1.isSame(d2, granularity || "d");
    } else if (operator === "!=") {
      return !d1.isSame(d2, granularity || "d");
    }
    return false;
  } catch (e) {
    console.log(e);
    return false;
  }
};
