import React, { useState, useEffect } from "react";
import { cloneDeep, toLower } from "lodash";
import { Button } from "reactstrap";
import { ModalHeader, openTouchInput, closeTouchInput } from "./index";
import translateText from "../translate";

// single select

export default function Selection(props) {
  const { inputName, defaultValue, element, list, isFilter, onConfirm, listOptionsWidth } = props;

  const [value, setValue] = useState(null);
  const [cursor, setCursor] = useState(-1);

  list.forEach((input) => {
    if (!input.group) input.group = "Other";
  });

  const groups = [];
  let pushOtherToLast = false;
  list.forEach((e) => {
    if (toLower(e.group) === "other") {
      pushOtherToLast = true;
    } else if (!groups.includes(e.group)) {
      groups.push(e.group);
    }
  });
  if (pushOtherToLast) groups.push("Other");
  const defaultGroup = groups[0];
  const [group, setGroup] = useState(defaultGroup);

  // initialize values whenever element changes
  useEffect(() => {
    setCursor(-1);
    setValue(defaultValue);
    const [getDefaultGroup] = list.filter(
      (f) => f.value === (defaultValue || {}).value || `${f.list_item_id}` === `${(defaultValue || {}).list_item_id}`
    );
    if (getDefaultGroup) {
      setGroup(getDefaultGroup.group);
    } else {
      setGroup(defaultGroup);
    }
  }, [element]);

  let options = cloneDeep(list);

  // filter options by group
  options = options.filter((x) => x.group === group);

  let parent = null;
  if (cursor > -1) {
    const [selectedList] = list.filter((f) => f.list_item_id === cursor);
    options = cloneDeep(selectedList.children);
    parent = selectedList;
  }

  let isOther = false;
  const text = (value || {}).label;
  const labels = options.map((x) => x.label);
  if (!labels.includes(text) && text) isOther = true;

  return (
    <>
      <ModalHeader
        id="select"
        onDelete={() => {
          const update = { value: "", label: "" };
          props.onChange(update);
          setValue(update);
        }}
        title={renderTitle()}
        onBack={cursor > -1 ? () => setCursor(-1) : null}
        uniqueId={props.uniqueId}
        useTouchInputNav={props.useTouchInputNav}
      />
      {renderGroups()}
      <div style={styles.selectionWrapper}>
        {options.length > 0 ? (
          options.map((e, i) => {
            let isSelected = false;
            if ((value || {}).value) {
              if (cursor === -1) {
                // not nested
                const parentValue = `${value.value}`.split(",")[0];
                if (parentValue === e.value) isSelected = true;
              } else {
                // nested
                const childValue = `${value.value}`.split(",")[1];
                if (childValue === e.value) isSelected = true;
              }
            }

            if (e.label === "Other" && isOther && `${e.list_item_id}` === `${(value || {}).list_item_id}`) isSelected = true;

            const style = { ...styles.option };
            if (isSelected) style.backgroundColor = "var(--secondary)";
            if (listOptionsWidth) style.width = listOptionsWidth;
            return (
              <Button
                id={`${inputName}_selection_item_${i}`}
                key={i}
                style={style}
                onMouseDown={(ev) => {
                  ev.preventDefault();
                  onChange(e, i);
                }}
              >
                {translateText(e.label, true)}
              </Button>
            );
          })
        ) : (
          <div className="mt-4">{translateText("no_options_available")}</div>
        )}
      </div>
      {onConfirm && (
        <div>
          <Button
            style={styles.confirmBtn}
            onMouseDown={(ev) => {
              ev.preventDefault();
              closeTouchInput();
            }}
          >
            {translateText("cancel")}
          </Button>
          <Button
            style={styles.confirmBtn}
            onMouseDown={(ev) => {
              ev.preventDefault();
              onConfirm(value);
            }}
          >
            {translateText("save")}
          </Button>
        </div>
      )}
    </>
  );

  function renderTitle() {
    return (
      <>
        {translateText(inputName)}
        {cursor > -1 && <> / {(value || {}).value.includes(",") ? value.label : translateText(value.label)}</>}
      </>
    );
  }

  function renderGroups() {
    if (groups.length < 2) return;
    return (
      <div style={styles.groupWrapper}>
        {groups.map((e, i) => {
          let isSelected = e === group;
          const style = { backgroundColor: "var(--grey)" };
          if (isSelected) style.backgroundColor = "var(--secondary)";

          return (
            <Button
              id={`${inputName}_selection_group_${i}`}
              key={i}
              className="mr-3 active"
              style={style}
              onMouseDown={(ev) => {
                ev.preventDefault();
                // set the active tab which will filter the list with groups = selected group
                setGroup(e);
                setCursor(-1);
              }}
            >
              {translateText(e)}
            </Button>
          );
        })}
      </div>
    );
  }

  function onChange(e) {
    if (!isFilter && e.children && e.children[0] && e.children[0].label) {
      // has children
      setCursor(e.list_item_id);
    }
    if (e.value === "custom") {
      const list_item_id = e.list_item_id;
      return openTouchInput({
        element,
        inputName: props.inputName,
        onChange: (e) => props.onChange({ value: e, label: e, list_item_id }),
        defaultValue: { value: isOther ? text : "" },
        type: "text",
        useTouchInputNav: true,
        uniqueId: props.uniqueId,
        onBack: (e) => {
          return openTouchInput({
            element,
            inputName: props.inputName,
            onChange: props.onChange,
            defaultValue: { value: e, label: e },
            type: "select",
            list,
            useTouchInputNav: true,
            uniqueId: props.uniqueId,
          });
        },
      });
    }
    if (cursor > -1) {
      e.label = `${translateText(parent.label)} ${translateText(e.label)}`;
      e.value = `${parent.value},${e.value}`;
    }
    setValue(e);
    if (props.onChange) props.onChange(e);
  }
}

const styles = {
  confirmBtn: { float: "right", marginBottom: 20, marginRight: 20, width: "12%" },
  selectionWrapper: {
    height: "420px",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    alignItems: "center",
    overflowX: "auto",
    overflowY: "hidden",
    padding: "5px 20px",
  },
  groupWrapper: {
    paddingLeft: 38,
    paddingTop: 30,
    textAlign: "left",
  },
  option: {
    width: "20%",
    backgroundColor: "var(--grey)",
    margin: 8,
  },
};
