import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { FaCaretDown, FaLongArrowAltLeft, FaTrashAlt, FaArrowCircleLeft, FaArrowCircleRight } from "react-icons/fa";
import { setReduxState, closeTouchInput as closeInput } from "../../redux/actions";

import Keyboard from "./keyboard";
import Selection from "./selection";
import Multi from "./multi";
import Datetime from "./datetime";

// params = {
//   element,
//   inputName,
//   onChange,
//   defaultValue,
//   type,
//   event,
//   list, (used for select and multiselect options)
//   units, (used for numeric-type keyboard input; unit selection)
// }

export function openTouchInput(params) {
  const box = params.element.getBoundingClientRect();

  // element's relative distance from top of page
  const top = box.top;

  // stop click's propagation
  if (params.event) params.event.stopPropagation();

  // ensure caret position is not out of bounds
  const { element, defaultValue, unit, ignoreCaretPos = false } = params;
  if (unit && defaultValue && !ignoreCaretPos) {
    const maxLen = ((defaultValue || {}).value || "").toString().length;
    element.selectionStart = element.selectionEnd = Math.min(element.selectionStart, maxLen);
  }

  TouchInput.openTouchInput({ ...params, top });
}

export function closeTouchInput() {
  TouchInput.closeTouchInput();
}

export default function TouchInput() {
  const state = useSelector((state) => {
    return { ...state.app.touchInput, keyboardLayout: state.app.system.keyboardLayout, system_info: state.app.system.system_info };
  });

  const { isOpen, type = "text", element } = state;

  const location = useLocation();
  const { pathname } = location;

  // close touch input on pathname change
  useEffect(() => {
    closeTouchInput();
  }, [pathname]);

  const dispatch = useDispatch();
  const setAppState = (state) => dispatch(setReduxState(state));

  TouchInput.openTouchInput = function (params) {
    if (params.element) params.element.focus();
    setAppState({ touchInput: { ...params, isOpen: true } });
  };

  TouchInput.closeTouchInput = function () {
    if (element) element.blur();
    dispatch(closeInput());
  };

  let className = "btm-modal";
  if (!isOpen) className += " closed";

  return (
    <div id="touch-input" className={className}>
      {renderInput()}
    </div>
  );

  function renderInput() {
    if (type === "text" || type === "numeric") return <Keyboard {...state} />;
    else if (type === "select") return <Selection {...state} />;
    else if (type === "multiselect") return <Multi {...state} />;
    else if (type === "timestamp" || type === "date") return <Datetime {...state} type={type} />;
  }
}

export function ModalHeader(props) {
  const { id, title, onBack, onDelete, useTouchInputNav: useNav } = props;
  // get all inputs
  const inputs = document.querySelectorAll(".input-nav-selector");
  return (
    <div className="btm-modal-title">
      {renderLeft()}
      <div style={styles.headerNav}>
        {useNav && inputs.length > 1 && (
          <div style={{ marginRight: 8 }}>
            <div
              id={`input_header_${id}_previous_input`}
              style={styles.navIcon}
              onClick={(e) => {
                e.stopPropagation();
                // find current index within inputs
                let myIndex = -1;
                for (let i = 0; i < inputs.length; i++) {
                  const e = inputs[i];
                  if (e.id === props.uniqueId) myIndex = i;
                }
                // click previous input
                if (myIndex > 0) inputs[myIndex - 1].click();
                else inputs[inputs.length - 1].click();
              }}
            >
              <FaArrowCircleLeft />
            </div>
          </div>
        )}
        <div style={{ paddingTop: 2 }}>{title}</div>
        {useNav && inputs.length > 1 && (
          <div style={{ marginLeft: 8 }}>
            <div
              id={`input_header_${id}_next_input`}
              style={styles.navIcon}
              onClick={(e) => {
                e.stopPropagation();
                // find current index within inputs
                let myIndex = -1;
                for (let i = 0; i < inputs.length; i++) {
                  const e = inputs[i];
                  if (e.id === props.uniqueId) myIndex = i;
                }
                // click next input
                if (myIndex < inputs.length - 1) inputs[myIndex + 1].click();
                else inputs[0].click();
              }}
            >
              <FaArrowCircleRight />
            </div>
          </div>
        )}
      </div>
      <FaCaretDown id="close_input" className="btm-modal-icon cursor-pointer" onClick={() => closeTouchInput()} />
    </div>
  );

  function renderLeft() {
    if (onBack) return <FaLongArrowAltLeft id={`input_header_${id}_back`} style={styles.headerIcon} onClick={onBack} />;
    if (onDelete) return <FaTrashAlt id={`input_header_${id}_delete`} style={styles.headerIcon} onClick={onDelete} />;
  }
}

const styles = {
  headerIcon: {
    position: "absolute",
    left: 40,
  },
  headerNav: {
    display: "flex",
    alignItems: "center",
  },
  navIcon: {
    fontSize: 36,
    margin: -10, // apply hitslop
    padding: "10px 30px",
    display: "inline-flex",
  },
};
