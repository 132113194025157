import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col, Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import _padStart from "lodash/padStart";
import _get from "lodash/get";
import PerfectScrollbar from "react-perfect-scrollbar";
import { NavLink, useHistory } from "react-router-dom";
import * as FaIcons from "react-icons/fa";
import AdmitPatient from "../pages/patientInfo/admitPatient";
import { Button, translateText, translateTimestamp, ConfirmationModal } from "../components";
import { logout } from "../api";
import { ErrorBoundary } from "react-error-boundary";
import { setReduxState } from "../redux/actions";
import { ErrorFallbackPage } from "../components/error";

export default function MainLayout(props) {
  return (
    <div className="main-container">
      <HeaderBar />
      <div className="main-holder">
        <SideBar />
        <main className="main-content">
          <ErrorBoundary FallbackComponent={ErrorFallbackPage}>{props.children}</ErrorBoundary>
        </main>
      </div>
    </div>
  );
}

const HeaderBar = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [admitPatient, setAdmitPatient] = useState(false);
  const [showUnvalidatedDataModal, setShowUnvalidatedDataModal] = useState(false);
  const state = useSelector((state) => {
    return {
      user_id: state.app.user_id,
      username: state.app.username,
      data: state.app.staticData,
      system: state.app.system,
    };
  });

  const hasRemoteConnection = useSelector((state) => state.app.hasRemoteConnection || false);
  const autoChartEnabled = useSelector((state) => state.app.autoChartEnabled || false);
  const location = useSelector((state) => state.app.staticData["MySubscriber::MyLocationReader"] || []);
  const locationDisplay = location.map((e) => e.name);

  const { username, user_id } = state;
  const { given_name = {}, family_name = {}, patient_id = {}, pat_admit_state = {}, date_of_birth = {} } = _get(state, "system.header", {});

  const patientName = `${_get(state, `data.${given_name.read_topic}[0].given_name`, "")} ${_get(
    state,
    `data.${family_name.read_topic}[0].family_name`,
    ""
  )}`;
  const patientId = _get(state, `data.${patient_id.read_topic}[0].patient_id`, "");
  const admitState = _get(state, `data.${pat_admit_state.read_topic}[0].pat_admit_state`, "");
  const isAdmitted = admitState && admitState !== 8;
  const dobObj = _get(state, `data.${date_of_birth.read_topic}[0].date_of_birth`, "");
  const dobMonth = _padStart(dobObj.month, 2, 0);
  const dobDay = _padStart(dobObj.day, 2, 0);
  const dobYear = `${dobObj.century}${_padStart(dobObj.year, 2, 0)}`;
  let dob = "";
  if (dobObj) {
    const dobString = `${dobMonth}/${dobDay}/${dobYear}`;
    const d = new Date(dobString);
    const m = moment(d);
    dob = translateTimestamp(m.format(state.system.system_info.date_format), state.system.system_info.date_format).toUpperCase();
  }
  return (
    <>
      {showUnvalidatedDataModal && (
        <ConfirmationModal
          id="unvalidated_data_modal"
          isOpen={showUnvalidatedDataModal}
          close={() => setShowUnvalidatedDataModal(false)}
          handleConfirm={() => setShowUnvalidatedDataModal(false)}
          body={
            <div style={{ lineHeight: 1.3, marginTop: 15 }}>
              There is un-validated data in Automated Charting, please validate all data in the automated charting app.
            </div>
          }
          title={translateText("automated_charting_unvalidated_data")}
          submitText={translateText("ok")}
        />
      )}
      {admitPatient && (
        <AdmitPatient
          history={history}
          config={state.system}
          isOpen={admitPatient}
          close={(hasUnvalidatedData) => {
            setAdmitPatient(false);
            if (hasUnvalidatedData) setShowUnvalidatedDataModal(true);
          }}
        />
      )}
      <div className="main-header">
        <div style={{ position: "fixed", top: 5, right: 350, fontSize: "16px", color: "white" }}>{locationDisplay.join(" ")}</div>
        {renderCurrentDateTime()}
        {renderTopLeftPanel()}
        <div style={{ display: "flex", flexGrow: 1, justifyContent: "center" }} onClick={() => history.push("/patient-info")}>
          {renderNameBlock()}
        </div>
        <div className="info-bar-area" onClick={() => history.push("/patient-info")}>
          {renderInfoBlock()}
        </div>
      </div>
      {renderDropdown()}
    </>
  );

  function renderTopLeftPanel() {
    return (
      <div style={styles.topLeftPanel}>
        {autoChartEnabled && (
          <div
            style={{ display: "flex", flexDirection: "column", alignItems: "center", lineHeight: 1.2 }}
            onClick={() => history.push("/automated-charting")}
          >
            <div>
              <FaIcons.FaCircle className="text-success" size="25" />
            </div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>{translateText("charting")}</div>
            <div style={{ fontSize: 12, textTransform: "uppercase" }}>{translateText("active")}</div>
          </div>
        )}
        {hasRemoteConnection && <div style={{ color: "#D500F9", marginTop: 6, marginRight: 25 }}>R</div>}
      </div>
    );
  }

  function renderNameBlock() {
    return (
      <div className="main-title" style={{ marginRight: 450, marginTop: 8 }}>
        <div className="main-label">{translateText("name")}</div>
        {isAdmitted ? (
          <h2>{patientName}</h2>
        ) : (
          <Button
            id="admit_patient"
            color="primary"
            className="ml-4 mt-3"
            onClick={(e) => {
              e.stopPropagation();
              setAdmitPatient(true);
            }}
          >
            {translateText("admit_patient")}
          </Button>
        )}
      </div>
    );
  }

  function renderInfoBlock() {
    return (
      <div className="info-bar">
        <div className="pl-3 pr-3">
          <div className="label">{translateText("mrn")}</div>
          {isAdmitted && <div className="txt">{patientId}</div>}
        </div>
        <div className="pl-3 pr-3">
          <div className="label">{translateText("dob")}</div>
          {isAdmitted && <div className="txt">{dob}</div>}
        </div>
      </div>
    );
  }

  function renderCurrentDateTime() {
    return (
      <div style={{ position: "fixed", top: 5, right: 5, fontSize: "16px", color: "white", width: "200px" }}>
        <span className="mr-2">
          <DateInfo date_format={(state.system.system_info || {}).date_format} />
        </span>
        <span>
          <TimeInfo />
        </span>
      </div>
    );
  }

  function renderDropdown() {
    return (
      <div style={{ position: "fixed", right: 40, top: 20, zIndex: 1 }}>
        <UncontrolledDropdown nav inNavbar className="pr-1">
          <DropdownToggle id="system_settings" nav caret>
            <FaIcons.FaCogs size={40} color="white" className="mb-2" />
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem id="users_page" tag="button" onClick={() => history.push("/users")}>
              <FaIcons.FaUsers className="mr-2" />
              {translateText("users")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem id="devices_page" tag="button" onClick={() => history.push("/devices")}>
              <FaIcons.FaLaptopMedical className="mr-2" />
              {translateText("devices")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem id="printer_settings" tag="button" onClick={() => history.push("/printer-settings")}>
              <FaIcons.FaPrint className="mr-2" />
              {translateText("printer_settings")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem id="system_page" tag="button" onClick={() => history.push("/system")}>
              <FaIcons.FaCog className="mr-2" />
              {translateText("system")}
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem
              id="logout"
              tag="button"
              onClick={() =>
                logout({ user_id, username }, () => dispatch(setReduxState({ is_auth: false, user_id: null, username: null, token: null })))
              }
            >
              <FaIcons.FaSignOutAlt className="mr-2" />
              {translateText("logout")}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    );
  }
};

const SideBar = () => {
  const system = useSelector((state) => state.app.system);
  const apps = system.apps || [];
  const totalAppsToShow = 4;
  const lastApp = apps[apps.length - 1] || {};

  return (
    <div className="sidebar">
      <PerfectScrollbar>
        <SidebarItem path="/home" name={"home"} isDefault={true} icon={"FaHome"} is_internal={true} />
        {apps.slice(0, totalAppsToShow).map((app) => (
          <SidebarItem key={app.name} path={app.link} name={app.name} icon={app.icon} is_internal={app.is_internal} />
        ))}
        {apps.length > totalAppsToShow ? (
          <More apps={apps} />
        ) : (
          <SidebarItem path={lastApp.link} name={lastApp.name} icon={lastApp.icon} is_internal={lastApp.is_internal} />
        )}
      </PerfectScrollbar>
    </div>
  );
};

function More(props) {
  const { apps } = props;
  const [showMoreModal, setShowMoreModal] = useState(false);

  return (
    <>
      {showMoreModal && (
        <Modal isOpen={showMoreModal} toggle={() => setShowMoreModal(false)}>
          <ModalHeader toggle={() => setShowMoreModal(false)}>{translateText("applications")}</ModalHeader>
          <ModalBody>
            <Row>
              <Col md={4}>
                <div id="home_menu_item_more_modal" onClick={() => setShowMoreModal(false)}>
                  <SidebarItem path="/home" name={"home"} isDefault={true} icon={"FaHome"} is_internal={true} showName={true} />
                </div>
              </Col>
              {apps.map((app) => (
                <Col md={4} key={app.name}>
                  <div id={`${app.name}_menu_item_more_modal`} onClick={() => setShowMoreModal(false)}>
                    <SidebarItem path={app.link} name={app.name} icon={app.icon} is_internal={app.is_internal} showName={true} />
                  </div>
                </Col>
              ))}
            </Row>
          </ModalBody>
        </Modal>
      )}
      <div id="show_more_modal" className="sidebar-item" style={{ color: "white" }} onClick={() => setShowMoreModal(true)}>
        <span className="icon">
          <FaIcons.FaEllipsisH className="mb-2" size={32} />
        </span>
      </div>
    </>
  );
}

function SidebarItem(props) {
  const { name, isDefault, icon, is_internal, showName } = props;
  let { path } = props;
  if (!is_internal) path = `/app/${name}`;
  const checkIsActive = (match, location) => {
    return (location.pathname === "/" && isDefault) || (match || {}).isExact || location.pathname.indexOf(path) > -1;
  };
  const Icon = FaIcons[icon];
  return (
    <NavLink id={`${name}_menu_item`} to={path} isActive={(match, location) => checkIsActive(match, location)} activeClassName="active">
      <div className="sidebar-item">
        <span className="icon">{icon && <Icon className="mb-2" size={32} />}</span>
        {showName && <span className="txt">{translateText(name)}</span>}
      </div>
    </NavLink>
  );
}

function DateInfo(props) {
  const { date_format } = props;
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setTime(moment()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return time.format(date_format).toUpperCase();
}

function TimeInfo() {
  const [time, setTime] = useState(moment());

  useEffect(() => {
    const interval = setInterval(() => setTime(moment()), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return time.format("HH:mm:ss");
}

const styles = {
  topLeftPanel: {
    backgroundColor: "rgba(31, 32, 41, 0.8)",
    borderRadius: 20,
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    width: "20%",
    height: 81,
    color: "white",
    display: "flex",
    alignItems: "center",
    fontSize: 35,
  },
};
