import { isJsonStr } from "../utils/helpers";

const initialState = {
  // authentication state vars
  is_auth: localStorage.getItem("user_id") ? true : false,
  token: localStorage.getItem("token"),
  user_id: localStorage.getItem("user_id"),
  roles: JSON.parse(isJsonStr(localStorage.getItem("roles")) ? localStorage.getItem("roles") : null),
  preferences: JSON.parse(isJsonStr(localStorage.getItem("preferences")) ? localStorage.getItem("preferences") : null),
  // app state vars
  socket: null,
  system: {},
  metrics: {},
  staticData: {},
  touchInput: {
    isOpen: false,
    type: "text",
  },
  patientInfoConfig: {},
  flowsheetState: {
    activeTab: "tasks",
    tasks_active: 0,
    summary_active: 0,
    tests_and_labs_active: 0,
    fluid_io_active: 0,
    scoring_active: 0,
  },
  trendsState: {
    activeView: 0,
    zoom: "60",
    events: [],
  },
  trendsConfig: [],
  taskState: {
    // 1: {}
    // 2: {}
    // ...
  },
  testsAndLabsState: {
    view: "current_tests",
    detailsData: {},
  },
  alarmData: {},
  persistentAppsState: {},
  autoChartEnabled: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case "SET_REDUX_STATE":
      return { ...state, ...action.payload };
    case "UPDATE_STATIC_DATA":
      return { ...state, staticData: { ...state.staticData, ...action.payload } };
    case "UPDATE_ALARM_DATA":
      return { ...state, alarmData: { ...state.alarmData, ...action.payload } };
    case "UPDATE_FLOWSHEET_STATE":
      return { ...state, flowsheetState: { ...state.flowsheetState, ...action.payload } };
    case "UPDATE_TRENDS_STATE":
      return { ...state, trendsState: { ...state.trendsState, ...action.payload } };
    case "SET_TASK_STATE":
      const taskUpdate = { ...(state.taskState[action.task_id] || {}), ...action.payload };
      return { ...state, taskState: { ...state.taskState, [action.task_id]: taskUpdate } };
    case "DEL_TASK_STATE_ID":
      return { ...state, taskState: { ...state.taskState, [action.task_id]: null } };
    case "DEL_TASK_STATE_ALL":
      return { ...state, taskState: {} };
    case "CLOSE_TOUCH_INPUT":
      return { ...state, touchInput: { ...state.touchInput, isOpen: false, top: 0, element: null } };
    case "UPDATE_TESTS_AND_LABS_STATE":
      return { ...state, testsAndLabsState: { ...state.testsAndLabsState, ...action.payload } };
    case "UPDATE_PERSISTENT_APPS_STATE":
      return { ...state, persistentAppsState: { ...state.persistentAppsState, ...action.payload } };
    default:
      return state;
  }
}
