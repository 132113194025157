import React, { Suspense, lazy, useState, useEffect } from "react";
import _isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter, Switch, useHistory } from "react-router-dom";
import createActivityDetector from "activity-detector";

// import Loading from "./components/loading";
import LoadingPage from "./pages/loading/index";
import MainLayout from "./layouts/mainLayout";
import TouchInput, { closeTouchInput } from "./components/touchInput/index";

import { setReduxState, updateStaticData, updateAlarmData, updatePersistentAppsState } from "./redux/actions";
import { postDataAPI, logout } from "./api";
import socketIOClient from "socket.io-client";
import config from "./config";
import { OPERATOR_BARCODE_METRIC_ID } from "./config";

const Login = lazy(() => import("./pages/login/index"));
const Home = lazy(() => import("./pages/home/index"));
const PatientInfo = lazy(() => import("./pages/patientInfo/index"));
const FlowSheet = lazy(() => import("./pages/flowsheet/index"));
const Devices = lazy(() => import("./pages/devices/index"));
const Users = lazy(() => import("./pages/users/index"));
const System = lazy(() => import("./pages/system/index"));
const ExternalApp = lazy(() => import("./pages/externalApp/index"));
const Trends = lazy(() => import("./pages/trends/index"));
const TrendsDetails = lazy(() => import("./pages/trends/viewDetails"));
const Reports = lazy(() => import("./pages/reports/index"));
const PrintPage = lazy(() => import("./pages/reports/printPage"));
const VentilatorControl = lazy(() => import("./pages/ventilatorControl/index"));
const VentilatorSettings = lazy(() => import("./pages/ventilatorControl/settings"));
const PrinterSettings = lazy(() => import("./pages/printer/index"));
const AutomatedCharting = lazy(() => import("./pages/automatedCharting/index"));

export default function Router() {
  return (
    <Suspense fallback={null}>
      <BrowserRouter basename="/">
        <Switch>
          <Route path="/" component={AppRouter} />
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}

function ExternalAppWrapper(props) {
  return <ExternalApp key={props.match.params[0]} {...props} />;
}

function AppRouter() {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const [loading, setLoading] = useState(true);
  const systemState = useSelector((state) => state.app.system);
  const { is_auth, reduxSocket } = useSelector((state) => {
    return {
      is_auth: state.app.is_auth,
      reduxSocket: state.app.socket,
    };
  });
  const persistentAppsState = useSelector((state) => state.app.persistentAppsState);
  const patientState = useSelector((state) => {
    const [patient] = state.app.staticData["MySubscriber::MyPatientReader"] || [];
    return patient || {};
  });

  const dispatch = useDispatch();
  const setAppState = (state) => dispatch(setReduxState(state));

  function cloudAuth(ip, port, hostname, jwt) {
    try {
      localStorage.setItem("token", jwt);
      localStorage.setItem("ip", ip);
      localStorage.setItem("port", port);
      localStorage.setItem("hostname", hostname);
      setAppState({ token: jwt, is_auth: true });
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (reduxSocket) {
      reduxSocket.on(OPERATOR_BARCODE_METRIC_ID, (d) => {
        if (history.location.pathname !== "/login" && history.location.pathname !== "/automated-charting") {
          const username = localStorage.getItem("username");
          const user_id = localStorage.getItem("user_id");
          if (d === username) {
            logout({ user_id, username }, () => dispatch(setReduxState({ is_auth: false, user_id: null, username: null, token: null })));
          } else {
            postDataAPI("/system/login-logout-extra-users", { username: d });
          }
        }
      });
    }
  }, [reduxSocket]);

  // load system data
  useEffect(() => {
    (async () => {
      try {
        if (params.vpn_ip && params.api_port && params.jwt) {
          cloudAuth(params.vpn_ip, params.api_port, params.hostname, params.jwt);
        }
        const response = await postDataAPI("/system/get-data");
        setAppState({ system: response, autoChartEnabled: response.autoChartEnabled });
        setLoading(false);
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(async () => {
    if (is_auth) await postDataAPI("/system/current-user");
  }, []);

  // connect databus sockets
  useEffect(() => {
    (async () => {
      try {
        if (!_isEmpty(systemState)) {
          const { system_info = {}, dataReaders = [], subscriberName } = systemState || {};
          const socketOpts = {};
          let prefix = "";
          let socketUrl = `${config.BASE_URL}:${system_info.patient_data_port}`;
          if (process.env.REACT_APP_UI_ENV === "cloud") {
            const hostname = localStorage.getItem("hostname");
            socketOpts.query = {
              hostname,
            };
            prefix = `${hostname}-`;
            socketUrl = config.PROXY_SOCKET_URL ? config.PROXY_SOCKET_URL : `${config.BASE_URL}:${config.PROXY_SOCKET}`;
          }
          const socket = socketIOClient(socketUrl, socketOpts);
          setAppState({ socket });
          socket.on("alarm_signals", (data) => {
            dispatch(updateAlarmData(data));
          });
          socket.on("auto_chart_enabled", (data) => {
            setAppState({ autoChartEnabled: data });
          });
          dataReaders.forEach((e) => {
            const topic = `${subscriberName}::${e.name}`;
            const socketTopic = `${prefix}${topic}`;
            socket.on(socketTopic, (data) => {
              console.log(topic, data);
              dispatch(updateStaticData({ [topic]: data }));
            });
          });
        }
      } catch (e) {
        console.log(e);
      }
    })();
  }, [systemState]);

  useEffect(() => {
    (systemState.apps || []).forEach((app) => {
      let persistentApp = null;
      if (app.name in persistentAppsState) {
        persistentApp = persistentAppsState[app.name];
      }

      if (persistentApp) {
        const patient = persistentApp.patient;
        if (patient.patient_id != patientState.patient_id || patient.pat_admit_state != patientState.pat_admit_state) {
          const iframe = document.getElementById(persistentApp.id);
          if (iframe) {
            document.body.removeChild(iframe);
            iframe.src = null;
          }

          dispatch(updatePersistentAppsState({ [app.name]: null }));
        }
      }
    });
  }, [systemState, persistentAppsState, patientState]);

  if (loading) return <LoadingPage />;

  return (
    <>
      <div id="close_touch_input" onClick={closeTouchInput}>
        <Switch>
          <Route exact path="/login" component={Login} />
          <Route exact path="/print" component={PrintPage} />
          <Route path="/" component={AuthRouter} />
        </Switch>
      </div>
      <TouchInput />
    </>
  );
}

// router for authenticated part of application
function AuthRouter(props) {
  const dispatch = useDispatch();

  const { token, system, user_id, username } = useSelector((state) => {
    return {
      token: state.app.token,
      system: state.app.system,
      user_id: state.app.user_id,
      username: state.app.username,
    };
  });

  let idleTime;
  if (system) idleTime = (system.system_info || {}).idle_logout_min;

  // if no token present, go to login screen
  useEffect(() => {
    if (!token) props.history.push(`/login${props.location.search}`);
  }, [token]);

  // logout if idle
  useEffect(() => {
    if (!idleTime) return;
    // conv minutes to milliseconds
    const detector = createActivityDetector({ timeToIdle: idleTime * 60000, inactivityEvents: [] });
    detector.on("idle", () => {
      logout({ user_id, username }, () => dispatch(setReduxState({ is_auth: false, user_id: null, username: null, token: null })));
    });
    return () => detector.stop(); // clean up
  }, [idleTime]);

  return (
    <MainLayout>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={Home} />
        <Route exact path="/patient-info*" component={PatientInfo} />
        <Route exact path="/flowsheet" component={FlowSheet} />
        <Route exact path="/trends" component={Trends} />
        <Route exact path="/trends/details" component={TrendsDetails} />
        <Route exact path="/reports" component={Reports} />
        <Route exact path="/automated-charting" component={AutomatedCharting} />
        <Route exact path="/devices" component={Devices} />
        <Route exact path="/users" component={Users} />
        <Route exact path="/system" component={System} />
        <Route exact path="/app*" component={ExternalAppWrapper} />
        <Route exact path="/ventilator-control" component={VentilatorControl} />
        <Route exact path="/ventilator-settings" component={VentilatorSettings} />
        <Route exact path="/printer-settings" component={PrinterSettings} />
      </Switch>
    </MainLayout>
  );
}
