import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _get from "lodash/get";
import _cloneDeep from "lodash/cloneDeep";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from "reactstrap";
import { FormField, TouchInputHandler, translateText, ConfirmationModal } from "../../components";
import { postDataAPI } from "../../api";
import { PulseLoader } from "react-spinners";
import { PATIENT_BARCODE_METRIC_ID } from "../../config";

function AdmitPatient(props) {
  const socket = useSelector((state) => state.app.socket);
  const [admitDisabled, setAdmitDisabled] = useState(false);
  const [isTransferingData, setIsTransferingData] = useState(false);
  const [transferData, setTransferData] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);
  const [data, setData] = useState({ pat_admit_state: { value: 2 } });
  const [showReadmitModal, setShowReadmitModal] = useState(null);
  const { config, isOpen, close } = props;

  useEffect(() => {
    socket.on(PATIENT_BARCODE_METRIC_ID, (d) => {
      const input = _get(config, `header.${inputs[0]}`, null);
      if (input) {
        const update = _cloneDeep(data);
        update[input.name] = { value: d, label: d };
        setData(update);
        admitPatient(update, true);
      }
    });
    return () => {
      socket.off(PATIENT_BARCODE_METRIC_ID);
    };
  }, []);

  const admitPatient = async function (patientData, isBarcodeScan = false, skipChecks) {
    setAdmitDisabled(true);

    // If it's a barcode scan, then only the patient id is required.
    // If it's not a barcode scan, then all fields are required.
    if (
      (isBarcodeScan === true && !(patientData.patient_id || {}).value) ||
      (isBarcodeScan === false &&
        (!(patientData.patient_id || {}).value ||
          !(patientData.family_name || {}).value ||
          !(patientData.given_name || {}).value ||
          !(patientData.date_of_birth || {}).value))
    ) {
      setAdmitDisabled(false);
      return setErrorMsg("all_fields_required");
    }
    setErrorMsg(null);
    if (!skipChecks) {
      const resp = await postDataAPI("/patient-info/admit-transfer-check", { data: patientData });
      if (resp.patient) {
        return setTransferData(resp);
      }
      const readmitCheck = await postDataAPI("/patient-info/readmit-check", { patient_id: (patientData.patient_id || {}).value });
      if ((readmitCheck || {}).readmit) {
        return setShowReadmitModal({ patientData, isBarcodeScan });
      }
    }
    const check = await postDataAPI("/automated-charting/check-unvalidated-data", { patient_id: patientData.patient_id.value });
    await postDataAPI("/patient-info/admit", { data: patientData });
    setAdmitDisabled(false);
    setShowReadmitModal(null);
    close(check.data);
    props.history.push("/home");
  };

  const admitTransferPatient = async function () {
    setIsTransferingData(true);
    await postDataAPI("/patient-info/admit", { data: transferData.patient, isTransfer: true });
    setIsTransferingData(false);
    close();
    props.history.push("/home");
  };

  const inputs = ["patient_id", "family_name", "given_name", "date_of_birth"];
  let rows = [];
  let btns = (
    <Button disabled={admitDisabled} id="admit_patient" onClick={() => admitPatient(data)}>
      {admitDisabled && <PulseLoader size={10} />} {translateText("patient_admit")}
    </Button>
  );
  inputs.forEach((e) => {
    const specs = _get(config, `header.${e}`, null);
    if (specs)
      rows.push(
        <Row key={e}>
          <Col>
            <FormField
              name={specs.name}
              type={specs.type}
              value={data[specs.name] || {}}
              onChange={(res) => {
                const update = _cloneDeep(data);
                update[specs.name] = res;
                setData(update);
              }}
              inModal
              uniqueId={specs.name}
            />
          </Col>
        </Row>
      );
  });
  if (transferData) {
    btns = (
      <>
        <Button id="admit_patient_cancel_transfer" onClick={() => setTransferData(null)} disabled={isTransferingData}>
          {translateText("cancel")}
        </Button>
        <Button id="admit_patient_continue_transfer" onClick={admitTransferPatient} disabled={isTransferingData}>
          {isTransferingData ? (
            <>
              <PulseLoader size={10} /> {translateText("transferring_patient")}
            </>
          ) : (
            translateText("continue")
          )}
        </Button>
      </>
    );
    rows = (
      <>
        <Row>
          <Col>
            <FormField
              name="patient_name"
              type={"text"}
              value={{ label: `${transferData.patient.given_name} ${transferData.patient.family_name}` }}
              readOnly
              inModal
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormField
              name="from_location"
              type={"text"}
              value={{ label: (transferData.patLocation || {}).map((m) => m.name).join(", ") }}
              readOnly
              inModal
            />
          </Col>
        </Row>
      </>
    );
  }
  return (
    <>
      {showReadmitModal && (
        <ConfirmationModal
          isOpen={showReadmitModal ? true : false}
          close={() => setShowReadmitModal(null)}
          handleConfirm={() => admitPatient(showReadmitModal.patientData, showReadmitModal.isBarcodeScan, true)}
          body={translateText("would_you_like_to_readmit_this_patient")}
          title={translateText("readmit_patient")}
          submitText={translateText("admit")}
        />
      )}
      <Modal isOpen={isOpen} toggle={() => close()} size="lg" backdrop="static">
        <ModalHeader toggle={() => close()}>{transferData ? translateText("transferring_patient") : translateText("admit_patient")}</ModalHeader>
        <TouchInputHandler height={450} spaceAbove={350}>
          <ModalBody>
            <div className="mt-3">{rows}</div>
          </ModalBody>
        </TouchInputHandler>
        <ModalFooter className="pt-0">
          {errorMsg && <div style={{ color: "white" }}>{translateText(errorMsg)}</div>}
          {btns}
        </ModalFooter>
      </Modal>
    </>
  );
}

export default AdmitPatient;
